<template>
  <div class="container">
    <div class="header-wrap">
      <div class="header">
        <div class="header-left">
          <a href="/blackpaper.pdf" target="_blank">
            BlackPaper
          </a>
        </div>
        <div class="header-right">
          <a href="https://twitter.com/WoodenFishInfo" target="_blank">
            <img src="../assets/images/x.svg" alt="x">
          </a>
          <a href="https://t.me/woodenfishinfo" target="_blank">
            <img src="../assets/images/t.svg" alt="twitter">
          </a>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="coin">
        Wooden Fish
      </div>

      <div class="intro">
        Harmonize Mind, Electronic Grind: Embrace Serenity with Electronic Woodfish.
      </div>

      <div class="players">
        <span class="count">
          <count-to :endVal="player"></count-to>
        </span>
        total players
      </div>

      <a class="join" href="https://t.me/WoodFish_bot" target="_blank">
        Join
      </a>

      <svg data-v-a83bd3b0="" t="1715344716532" viewBox="0 0 1365 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2002" width="128" height="128" class="icon scaleUpDown" style="animation: 0.2s ease 0s 1 normal none running scaleUpDown;"><path data-v-a83bd3b0="" fill="#fff" d="M1.450653 780.39695c-10.175905 64.255398 36.031662 101.161718 59.626108 112.361614 23.594445 11.178562 63.274073 0 78.825927 0 116.542907 11.178562 366.759228 131.220103 678.606972 131.220103 0 0 504.635269 7.445264 543.31224-360.487287 9.19458-95.529771 4.885288-277.458732-71.039334-286.162651-63.956734-8.426588-102.121709 4.074628-183.315615 20.565141-53.908828 10.922564-189.011561 29.973052-212.926004 44.970245-260.989553 118.718887-403.324219 204.371417-442.299853 217.128631-29.439724 0-54.975485-7.359931-62.100752-69.972677 0-25.706426 98.089747-87.039184 140.137353-96.959091C682.660267 452.869354 796.365867 435.333519 809.720409 435.333519c19.263819 0 441.489194-101.588381 454.438406-111.188291 12.949212-9.59991 26.62375-18.986489 26.623751-52.543508 0-15.359856-33.813016-49.663534-72.319322-91.455142-45.674238-49.556869-99.94573-107.092329-140.606682-120.788201C1002.934597 20.958737 856.077308-10.912964 727.779844 3.572233 446.929143 35.273269 271.677453 342.662388 256.424263 363.995521c-64.852725 90.708483-116.542907 205.587406-143.678653 256.296264C86.548522 669.272659 11.71189 735.149375 1.450653 780.39695z" p-id="2003"></path></svg>

      <div>
        <count-to :endVal="fish" class="count-to-fish"></count-to>
        Prayer
      </div>
    </div>

    <div class="decorate-left">
      <svg t="1715340652474" class="icon" viewBox="0 0 1029 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1914" width="128" height="128"><path d="M1024.727025 511.98976c0 282.771945-229.217816 511.98976-511.98976 511.98976-282.766825 0-511.98976-229.217816-511.98976-511.98976 0-282.766825 229.222936-511.98976 511.98976-511.98976 282.771945 0 511.98976 229.222936 511.98976 511.98976" fill="#F7931A" p-id="1915"></path><path d="M637.048379 661.623888a55.842723 55.842723 0 0 1-27.145697 33.417571 55.858083 55.858083 0 0 1-42.827943 4.444071l-144.181437-42.940581 32.122238-107.840403 144.171196 42.945701h0.00512c29.726125 8.852303 46.713946 40.242395 37.856523 69.973641m48.628787-221.00038a55.929761 55.929761 0 0 1 4.438952 42.833063c-8.857423 29.721006-40.262875 46.698586-69.973641 37.861643h-0.00512l-144.176316-42.940582 32.111998-107.840403 144.181436 42.940581a55.888802 55.888802 0 0 1 33.422691 27.145698m64.54143-34.917702c-16.48095-30.458271-43.836563-52.678626-77.01862-62.560029l-19.076738-5.677966 26.705385-89.669887-68.519589-20.407912-26.705386 89.669887-58.49995-17.423012 26.710506-89.669886-16.414392-4.889503-66.49723-19.803763-25.742845 86.434111-81.037739-24.135197-22.870583 76.798464 81.037739 24.140317-83.254655 279.536169-81.037739-24.135197-22.870583 76.803584 81.03262 24.130077-21.723726 72.953421 16.409272 4.889502 66.49723 19.803764 22.691386-76.189196 58.638188 17.463971-22.691387 76.194316 68.243116 20.325993 22.691386-76.194316 19.209856 5.718926a130.255315 130.255315 0 0 0 37.185816 5.452691c21.247575 0 42.295474-5.278614 61.51045-15.677127 30.458271-16.48095 52.673507-43.831443 62.560028-77.023739 10.393392-34.912582 5.263255-70.777464-11.268894-100.298794 30.01284-15.661767 53.927881-42.935461 64.331513-77.863403 9.886522-33.192296 6.251395-68.243115-10.224435-98.696266" fill="#FFFFFF" p-id="1916"></path></svg>

      <svg t="1715340711163" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2966" width="128" height="128"><path d="M512.099961 0.399844c282.689574 0 511.800078 229.110504 511.800078 511.800078s-229.110504 511.800078-511.800078 511.800078S0.299883 794.889496 0.299883 512.199922 229.510348 0.399844 512.099961 0.399844z" fill="#3E5BF2" p-id="2967"></path><path d="M512.199922 147.242483v510.000781l224.112456-134.34752zM512.199922 877.15736l224.112456-321.774307-224.112456 135.547052zM512.199922 147.242483v510.000781L287.987505 522.895744z" fill="#FFFFFF" p-id="2968"></path><path d="M512.199922 690.930105v186.227255L287.987505 555.383053z" fill="#FFFFFF" p-id="2969"></path></svg>

      <svg t="1715340724833" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3998" width="128" height="128"><path d="M1023.082985 511.821692c0 281.370746-228.08199 509.452736-509.452736 509.452736-281.360557 0-509.452736-228.08199-509.452737-509.452736 0-281.365652 228.092179-509.452736 509.452737-509.452737 281.370746 0 509.452736 228.087085 509.452736 509.452737" fill="#1BA27A" p-id="3999"></path><path d="M752.731701 259.265592h-482.400796v116.460896h182.969951v171.176119h116.460895v-171.176119h182.96995z" fill="#FFFFFF" p-id="4000"></path><path d="M512.636816 565.13592c-151.358408 0-274.070289-23.954468-274.070289-53.50782 0-29.548259 122.706786-53.507821 274.070289-53.507821 151.358408 0 274.065194 23.959562 274.065194 53.507821 0 29.553353-122.706786 53.507821-274.065194 53.50782m307.734925-44.587303c0-38.107065-137.776398-68.995184-307.734925-68.995184-169.953433 0-307.74002 30.888119-307.74002 68.995184 0 33.557652 106.837333 61.516418 248.409154 67.711363v245.729433h116.450707v-245.632637c142.66205-6.001353 250.615085-34.077294 250.615084-67.808159" fill="#FFFFFF" p-id="4001"></path></svg>

      <svg t="1715340724833" class="icon" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3999" width="128" height="128"><path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#0098EA"></path><path d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z" fill="white"></path></svg>
    </div>

    <star-bg></star-bg>
  </div>
</template>

<script>
import StarBg from '../components/star-bg.vue'
import CountTo from 'vue-count-to'
import axios from 'axios'

const dashApi = 'https://app.woodenfish.info/api/dash'

export default {
  name: 'Home',
  components: {
    StarBg,
    CountTo
  },
  data () {
    return {
      fish: 0,
      player: 0,
      timer: null
    }
  },
  mounted () {
    this.getDash()
    this.timer = setInterval(() => {
      this.getDash(false)
    }, 10000)
  },
  destroyed () {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    getDash (reload = true) {
      axios.get(dashApi).then(res => {
        this.fish = Number(res.data.data.points) || 0
        this.player = Number(res.data.data.users) || 0
      }).catch(() => {
        if (reload) {
          const timer = setTimeout(() => {
            this.getDash()
            clearTimeout(timer)
          }, 5000)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../sass/var.scss';
.container {
  >* {
    z-index: 1;
    position: relative;
  }
  .header-wrap {
    border-bottom: 1px solid #fff3;
  }
  .black-paper {
    opacity: .4;
    cursor: not-allowed;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    max-width: 1248px;
    margin: 0 auto;
    height: 3.75rem;
    box-sizing: border-box;
  }

  .header-left {
    font-weight: bold;
    a {
      color: $text;
      text-decoration: none;
    }
  }

  .header-right {
    a {
      margin-left: 12px;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }

  .count-to-fish {
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    max-width: 1200px;
    padding: 0 16px;
    margin: 160px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .8rem;
    position: relative;
    z-index: 11;

    .coin {
      font-weight: 800;
      font-size: 2.5rem;

      @media (min-width: 768px) {
        font-size: 3.75rem;
      }
    }

    .intro {
      font-size: 1rem;
      color: $textSecondary;
      text-align: center;
      line-height: 1.5;
      @media (min-width: 768px) {
        font-size: 1.25rem;
      }
    }
  }

  .players {
    color: $textSecondary;
    .count {
      font-variant-numeric: lining-nums tabular-nums;
      margin-left: 12px;
      color: $text;
      font-size: 1rem;
      @media (min-width: 768px) {
        font-size: 1.25rem;
      }
    }
  }

  .join {
    border-radius: 1rem;
    background-color: #f90;
    font-weight: 600;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    display: flex;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 12px;
    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .decorate-left {
    top: 50vh;
    left: 0;
    z-index: 10;
    position: fixed;
    transform: translateY(-50%);

    svg:nth-of-type(1) {
      top: 2.5rem;
      left: 12rem;
      width: 2rem;
      height: 2rem;
      position: relative;

      @media screen and (max-width: 768px) {
        left: .5rem;
      }
    }

    svg:nth-of-type(2) {
      top: 20rem;
      left: 6rem;
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
    }

    svg:nth-of-type(3) {
      top: 22rem;
      left: 20rem;
      width: 1rem;
      height: 1rem;
      position: relative;
    }

    svg:nth-of-type(4) {
      top: 25rem;
      left: 10rem;
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
    }
  }
}
</style>
